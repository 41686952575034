import React from 'react';


import SEO from '../../components/blocks/SEO';
import SimpleLayout from '../../components/blocks/SimpleLayout/SimpleLayout';
import SignupPage from '../../modules/signup';

const Page = ({ pathContext, location }) => (
  <SimpleLayout>
    <SEO title="Authentic Evergreen Marketing | Deadline Funnel" />
    <SignupPage signupTitle="Start Your Free 14-Day Trial! No Credit Card Required." special={false} plan="create" location={location} funnel="no-cc" />
  </SimpleLayout>
);

export default Page;